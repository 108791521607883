import { memo, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  RootRevealer,
  CloseButton,
  PositionedImage,
  PositionedVideoPlayer,
  MediaContainer,
  MediaCaption,
} from "./styled";
import { hideModalViewer } from "../../../redux/local/ducks/modal-viewer-duck";
import { useLastTruthy, useLanguage, useLocalState } from "ripple";

/**
 * The modal viewer receives the data provided when dispatching its "show" action.
 * By default, the data is assumed to be a media or an image URL, but it can be changed to anything
 * if you need to display more stuff.
 *
 * Usage: `dispatch(showModalViewer(viewerData))`
 */
const ModalViewer = memo(({ ...rest }) => {
  useLanguage();

  const dispatch = useDispatch();

  const viewerData = useLocalState((local) => local.modalViewer);

  const [lastTruthyViewerData] = useLastTruthy(viewerData); // Keep track of the last data while we're disappearing
  const videoPlayerRef = useRef(null);

  const onCloseButtonClick = useCallback(() => {
    dispatch(hideModalViewer());
    videoPlayerRef.current?.pause();
  }, [dispatch]);

  const src = typeof lastTruthyViewerData === "string" ? lastTruthyViewerData : lastTruthyViewerData?.src;
  const caption = lastTruthyViewerData?.caption;

  const renderImage = (src) => {
    return <PositionedImage className="debug-show-area" src={src} scaling="fit" />;
  };

  const renderVideoPlayer = (src) => {
    return <PositionedVideoPlayer ref={videoPlayerRef} className="debug-show-area" src={src} scaling="fit" />;
  };

  return (
    <RootRevealer
      {...rest}
      transition="slide-in-and-out-from-bottom"
      staggerOptions={{ delayBefore: 100, interval: 200 }}
      disableExitStagger={true}
      reveal={!!viewerData}
    >
      <MediaContainer>
        {src && (typeof src === "string" || src?.type === "image" ? renderImage(src) : renderVideoPlayer(src))}
        <MediaCaption>{caption}</MediaCaption>
      </MediaContainer>
      <CloseButton onClick={onCloseButtonClick} />
    </RootRevealer>
  );
});

export default ModalViewer;
