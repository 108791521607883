// This triggers webpack bundling of styles.
// The styles are injected in a <style> tag at runtime,
// which makes them HMR-compatible.
import "../styles/main.less";

import { App } from "ripple";

import nav from "./core/nav";
import stats from "./core/stats";
import localReducer from "./redux/local/local-reducer";
import localEpic from "./redux/local/local-epic";
import masterReducer from "./redux/master/master-reducer";
import masterEpic from "./redux/master/master-epic";

import AppRoot from "./react/app-root";

App.bootstrap({
  element: document.querySelector("#app"),
  appRoot: AppRoot,
  nav: nav, // Dynamic navigation logic
  stats: stats, // Custom stats to add in the `/analytics` dashboard
  localReducer: localReducer, // Root reducer for the `local` portion of the local store
  localEpic: localEpic, // Epics triggered by dispatches to the local store
  masterReducer: masterReducer, // Root reducer for the IPC master store (maintained by the IPC master)
  masterEpic: masterEpic, // Epics triggered by IPC dispatches (run on the master store maintained by the IPC master)
  fonts: {
    typekit: {
      id: "dum1idb",
    },
  },
});
