import styled from "styled-components";
import { Button, Revealer, staggerStep, Media, VideoPlayer, Text } from "ripple";

export const RootRevealer = styled(Revealer)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  padding: 100px 178px;
  z-index: 100000;

  /* Overriding this to force the revealer to be clickable, */
  /* else it inherits the pointer-events: none of the overlay */
  pointer-events: ${(props) => (props.reveal ? "auto !important" : "none !important")};
`;

export const PositionedImage = staggerStep({ transition: "scale-up-fade" })(
  styled(Media)`
    flex-grow: 1;
  `
);

export const PositionedVideoPlayer = staggerStep({ transition: "scale-up-fade" })(
  styled(VideoPlayer).attrs((props) => ({ autoPlay: true }))`
    flex-grow: 1;

    .controls {
      border: none;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.8);
      height: 60px;

      & > * {
        margin: 10px 24px;
      }
    }

    .play-button {
      color: white;
      font-size: 26px;
    }

    .progress-bar {
      margin: 0;

      .track {
        height: 20px;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 4px;
      }

      .progress {
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 4px;
      }
    }

    .time-label {
      color: white;
      font-family: inherit;
      font-size: 28px;
    }
  `
);

export const CloseButton = staggerStep({ order: 0 })(
  styled(Button)`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00d";
      font-size: 70px;
      color: white;
    }

    &.clicked {
      transition: all 100ms ease-in-out;
      transform: scale3d(0.8, 0.8, 1);
    }
  `
);

export const MediaContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
`;

export const MediaCaption = staggerStep({})(
  styled(Text)`
    position: absolute;
    bottom: -30px;
    left: 0;
    color: white;
    text-transform: uppercase;
    max-width: 1564px;
  `
);
