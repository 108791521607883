import { useLanguage } from "ripple";

import { Overlay } from "./styled";
import ModalViewer from "../modal-viewer";

const AppOverlay = () => {
  useLanguage();
  return (
    <Overlay>
      <ModalViewer />
    </Overlay>
  );
};

export default AppOverlay;
