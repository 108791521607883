import styled from "styled-components";

import { Image, Page as RipplePage, staggerStep, Text } from "ripple";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (orientation: landscape) {
    margin-top: 24vmin;
    flex-direction: row;
    flex: none;
  }
`;

export const MessageAndButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Composition = staggerStep({})(
  styled(Image)`
    margin-top: 15vmax;
    background-color: black;
    border-radius: 10px;
    width: 93vmin;
    height: 93vmin;

    @media (orientation: landscape) {
      width: 54vmin;
      height: 54vmin;
      margin-top: 0;
    }

    @media (min-aspect-ratio: 3/5) {
      max-width: 50vmax;
      max-height: 50vmax;
    }

    @media (min-aspect-ratio: 5/3) {
      width: 54vmin;
      height: 54vmin;
    }
  `
);

export const Message = staggerStep({})(
  styled(Text)`
    margin-top: 2vmax;
    font-size: 14px;
    line-height: 19px;
    margin-left: 5vmin;
    margin-bottom: 2vmin;
    margin-right: 2vmin;
    max-width: 50vmax;

    @media (orientation: landscape) {
      margin-left: 7vmin;
    }
  `
);

export const Buttons = staggerStep({})(
  styled.div`
    width: 92vmin;
    margin-bottom: 4vmax;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .ripple-button {
      flex: 1;
    }

    @media (orientation: landscape) {
      margin-bottom: 0;
      width: 40vmax;
    }
  `
);
