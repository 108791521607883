import React, { useState, useRef, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigator, resource, Strings, useData } from "ripple";

import {
  Page,
  Center,
  CenterBackground,
  BottomLeftButtons,
  BottomRightButtons,
  BottomLeftButton,
  BottomRightButton,
  CenterSnowflake,
  BottomCenterButtons,
  BottomCenterButtonsRow,
  BottomCenterButtonsSplitter,
  BottomButtonSelectionDot,
  BottomCenterButton,
  CenterTutorialImage,
  CenterTutorialText,
} from "./styled";
import { BottomButtonImage, BottomButtonText, UpperLeftLogo, UpperRightLanguageButton } from "../../common";

const DrawingPage = React.memo(() => {
  const client = useData((data) => data.root);

  const snowflakeRef = useRef(null);
  const [activeLineType, setActiveLineType] = useState(0);

  const [showTutorial, setShowTutorial] = useState(true);
  const [hasDrawn, setHasDrawn] = useState(false);

  const onUndoClick = useCallback(() => {
    snowflakeRef.current.undo();
  }, []);

  const onSubmitClick = useCallback(() => {
    snowflakeRef.current.snapshot().then((blob) => {
      Navigator.navigate({ path: "/snowing", replaceQuery: { drawing: URL.createObjectURL(blob) } });
    });
  }, []);

  const onSnowflakeDraw = useCallback((lineCount) => {
    setShowTutorial(false);
  }, []);

  const onSnowflakeChange = useCallback((lineCount) => {
    setHasDrawn(lineCount === 0 ? false : true);
  }, []);

  const createOnLineTypeClick = (index) => () => {
    setActiveLineType(index);
  };

  const [mounting, setMounting] = useState(true);
  useEffect(() => {
    setTimeout(() => setMounting(false), 1000);
  }, []);

  return (
    <Page background={client.wantedMedia("Background", "FullscreenImage")}>
      <UpperLeftLogo src={client.wantedMedia("UpperLeftLogo", "PreserveSizeWithAlpha")} />
      <UpperRightLanguageButton />
      <Center disabled={mounting}>
        <CenterBackground />
        <CenterSnowflake
          ref={snowflakeRef}
          lineType={activeLineType}
          onDraw={onSnowflakeDraw}
          onChange={onSnowflakeChange}
        />
      </Center>
      <CenterTutorialImage show={showTutorial} src={resource("images/ScreenSaver_Snowflake@3x.png")} />
      <CenterTutorialText show={showTutorial}>{client.wantedText("TutorialText")}</CenterTutorialText>
      <BottomLeftButtons>
        <BottomLeftButton onClick={onUndoClick} disabled={!hasDrawn}>
          <BottomButtonImage src={resource("images/Button_Undo@2x.png")} />
          <BottomButtonText>{Strings.localized("UndoButton")}</BottomButtonText>
        </BottomLeftButton>
      </BottomLeftButtons>
      <BottomCenterButtons>
        <BottomCenterButtonsRow>
          <BottomCenterButton onClick={createOnLineTypeClick(0)} disabled={activeLineType === 0}>
            <BottomButtonSelectionDot show={activeLineType === 0} style={{ opacity: activeLineType === 0 ? 1 : 0 }} />
            <BottomButtonImage src={resource("images/Button_Style_01@2x.png")} />
          </BottomCenterButton>
          <BottomCenterButton onClick={createOnLineTypeClick(1)} disabled={activeLineType === 1}>
            <BottomButtonSelectionDot show={activeLineType === 1} style={{ opacity: activeLineType === 1 ? 1 : 0 }} />
            <BottomButtonImage src={resource("images/Button_Style_02@2x.png")} />
          </BottomCenterButton>
        </BottomCenterButtonsRow>
        <BottomCenterButtonsSplitter />
        <BottomButtonText>{Strings.localized("StyleButtons")}</BottomButtonText>
      </BottomCenterButtons>
      <BottomRightButtons>
        <BottomRightButton onClick={onSubmitClick} disabled={!hasDrawn}>
          <BottomButtonImage src={resource("images/Button_Finish@2x.png")} />
          <BottomButtonText>{Strings.localized("FinishButton")}</BottomButtonText>
        </BottomRightButton>
      </BottomRightButtons>
    </Page>
  );
});

DrawingPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  local: PropTypes.object,
  remote: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({
  language: state.language,
  data: state.data,
  local: state.local,
  remote: state.remote,
}))(DrawingPage);
