import styled from "styled-components";

import { Page as RipplePage, staggerStep, Image, resource, Button, Text, Video } from "ripple";
import { BottomButton, lightBlue, screenMargin } from "../../common";
import { transparentize } from "polished";
import Snowflake from "../../components/snowflake";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Center = staggerStep({})(
  styled.div`
    position: absolute;
    height: 92vmin;
    width: 92vmin;

    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

    display: flex;
    justify-content: center;
    align-items: center;
  `
);

export const CenterBackground = styled(Image).attrs((props) => ({ src: resource("images/DrawingSpace.png") }))``;

export const CenterSnowflake = styled(Snowflake)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CenterTutorialImage = styled(Image).attrs((props) => ({ scaling: "fill" }))`
  position: absolute;
  height: 100vmin;
  width: 100vmin;
  transform: scale3d(0.9, 0.9, 1);

  pointer-events: none;
  transition: opacity 200ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const CenterTutorialText = staggerStep({})(
  styled(Text)`
    font-size: 16px;
    font-weight: 700;

    pointer-events: none;
    transition: opacity 200ms ease-in-out;
    opacity: ${(props) => (props.show ? 1 : 0)};
  `
);

export const LineTypePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  right: 70px;

  transition: opacity 300ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
`;

export const LineType = staggerStep({})(
  styled(Button)`
    width: 340px;
    height: 130px;
    border-radius: 28px;
    transition: all 250ms ease-in-out;
    border: ${(props) => (props.active ? `8px solid ${lightBlue}` : `8px solid ${transparentize(0.85, lightBlue)}`)};
    margin: 35px 0;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.clicked {
      transform: scale3d(0.8, 0.8, 0.8);
    }
  `
);

export const LineTypeImage = styled(Image)`
  transform: rotate3d(0, 0, 1, 75deg);
`;

export const BottomButtons = styled.div`
  transition: opacity 300ms ease-in-out;
`;

export const BottomLeftButtons = styled(BottomButtons)`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  bottom: ${screenMargin};
  left: ${screenMargin};

  @media (orientation: landscape) {
    bottom: 40vmin;
  }
`;

export const BottomCenterButtons = staggerStep({ transition: "slide-in-and-fade-from-bottom" })(
  styled.div`
    width: 40vmin;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    bottom: ${screenMargin};

    @media (orientation: landscape) {
      left: ${screenMargin};
    }
  `
);

export const BottomButtonSelectionDot = styled.div`
  width: 2vmin;
  height: 2vmin;
  border-radius: 1vmin;
  background-color: white;

  transition: opacity 200ms ease-in-out;
`;

export const BottomCenterButtonsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BottomCenterButtonsSplitter = styled.div`
  margin-top: 6px;
  width: 90%;
  height: 1px;
  background-color: white;
`;

export const BottomRightButtons = styled(BottomButtons)`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  bottom: ${screenMargin};
  right: ${screenMargin};
`;

export const BottomLeftButton = styled(BottomButton)``;

export const BottomCenterButton = styled(BottomButton)`
  &.disabled {
    opacity: 1;
  }
`;

export const BottomRightButton = styled(BottomButton)``;
