import { memo, useRef } from "react";
import PropTypes from "prop-types";
import mergeRefs from "react-merge-refs";
import anime from "animejs";

import { SnowRoot } from "./styled";
import { Random, useInterval, useIsMountedRef, useMeasure } from "ripple";

const Snow = memo(({ src, spawnInterval, scaleMultiplier, opacityMultiplier, centerFirst, ...rest }) => {
  const rootRef = useRef(null);
  const [measureRef, { width: viewWidth, height: viewHeight }] = useMeasure();

  const isMountedRef = useIsMountedRef();
  const generatedCountRef = useRef(0);

  useInterval(() => {
    if (!isMountedRef.current) return;

    const margin = 50;
    const rootElement = rootRef.current;

    const scale = centerFirst && generatedCountRef.current === 0 ? 0.6 : Random.float(0.3, 0.6);
    const angleDelta = Random.int(-360, 360);

    const x = centerFirst && generatedCountRef.current === 0 ? viewWidth / 2 : Random.float(0, viewWidth);
    generatedCountRef.current += 1;

    const image = document.createElement("img");
    image.src = src;
    image.onload = () => {
      image.style.position = "absolute";
      image.style.left = `${x - image.width / 2}px`;
      image.style.top = `-${image.height + margin}px`;
      image.style.transform = `scale3d(${scale * scaleMultiplier}, ${scale * scaleMultiplier}, 1)`;
      image.style.opacity = scale * opacityMultiplier;

      rootElement.appendChild(image);

      anime({
        targets: image,
        top: `${viewHeight + margin}px`,
        rotateZ: `${angleDelta}deg`,
        duration: 5000 / scale,
        easing: "linear",
        complete: () => rootElement.removeChild(image),
      });
    };
  }, spawnInterval);

  return <SnowRoot {...rest} ref={mergeRefs([rootRef, measureRef])}></SnowRoot>;
});

Snow.propTypes = {
  src: PropTypes.string,
  spawnInterval: PropTypes.number,
  scaleMultiplier: PropTypes.number,
  opacityMultiplier: PropTypes.number,
  centerFirst: PropTypes.bool,
};

Snow.defaultProps = {
  scaleMultiplier: 1,
  opacityMultiplier: 1,
};

export default Snow;
