import { memo, useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Download,
  Load,
  Log,
  resource,
  Strings,
  Upload,
  useConstant,
  useData,
  useLanguage,
  useQueryString,
} from "ripple";
import {
  BottomButton,
  BottomButtonImage,
  BottomButtonText,
  UpperLeftLogo,
  UpperRightLanguageButton,
} from "../../common";

import { Composition, Page, Message, Buttons, Content, MessageAndButtons } from "./styled";

const SharingPage = memo(() => {
  const { drawing } = useQueryString();

  const client = useData((data) => data.root);
  const language = useLanguage();

  const shareAPISupported = !!navigator.share;
  const imageUUID = useConstant(() => uuidv4());

  const [compositionBlob, setCompositionBlob] = useState(null);
  const [composition, setComposition] = useState(null);
  const [compositionPublicUrl, setcompositionPublicUrl] = useState(null);

  const onLinkClick = useCallback(() => {
    const urlTextInfo = client.wantedText("LinkUrl");
    if (urlTextInfo.exists) window.open(urlTextInfo.value, "_blank");
  }, [client]);

  const onShareClick = useCallback(() => {
    navigator.share({ url: compositionPublicUrl }).catch((error) => {
      Log.error(`Share Failed: ${error.message}`);
    });
  }, [compositionPublicUrl]);

  const onDownloadClick = useCallback(() => {
    Download.url("snowflake.jpg", composition);
  }, [composition]);

  useEffect(() => {
    if (!compositionBlob || !shareAPISupported) return;
    Upload.file(`${Strings.localized("SnowflakeFilePrefix").value}-${imageUUID}.jpg`, compositionBlob).then(
      setcompositionPublicUrl
    );
  }, [compositionBlob, imageUUID, shareAPISupported]);

  useEffect(() => {
    const backgroundMedia = client.wantedMedia("SnapshotBackground", "FullscreenImage");
    Promise.all([Load.image(backgroundMedia.url), Load.image(drawing)])
      .then(([backgroundImage, drawingImage]) => {
        const size = 1136;

        const canvas = document.createElement("canvas");
        canvas.width = size;
        canvas.height = size;

        const context = canvas.getContext("2d");
        context.drawImage(backgroundImage, 0, 0, size, size);

        context.drawImage(drawingImage, 34, 90, 900, 900);
        context.drawImage(drawingImage, 898, 90, 200, 200);

        context.font = "48px SemplicitaPro";
        context.fillStyle = "white";
        context.textAlign = "right";
        context.fillText(client.wantedText("SnapshotOverlayText", language).value, 1100, 1080);

        canvas.toBlob(
          (blob) => {
            setCompositionBlob(blob);
            setComposition(URL.createObjectURL(blob));
          },
          "image/jpeg",
          0.8
        );
      })
      .catch(() => Log.error("Failed to load composition images!"));
  }, [client, drawing, language]);

  const renderComposition = () => <Composition src={composition} />;

  const renderMessage = () => <Message>{client.wantedText("SnapshotMessage")}</Message>;

  const renderButtons = () => (
    <Buttons>
      <BottomButton action="timeout">
        <BottomButtonImage src={resource("images/Button_Restart@2x.png")} />
        <BottomButtonText>{Strings.localized("RestartButton")}</BottomButtonText>
      </BottomButton>
      <BottomButton onClick={onLinkClick}>
        <BottomButtonImage src={resource("images/Button_Link@2x.png")} />
        <BottomButtonText>{Strings.localized("LinkButton")}</BottomButtonText>
      </BottomButton>
      <BottomButton disabled={!composition} onClick={onDownloadClick}>
        <BottomButtonImage src={resource(`images/Button_Download@2x.png`)} />
        <BottomButtonText>{Strings.localized("DownloadButton")}</BottomButtonText>
      </BottomButton>
      {shareAPISupported && (
        <BottomButton disabled={!compositionPublicUrl} onClick={onShareClick}>
          <BottomButtonImage src={resource(`images/Button_Share@2x.png`)} />
          <BottomButtonText>{Strings.localized("ShareButton")}</BottomButtonText>
        </BottomButton>
      )}
    </Buttons>
  );

  return (
    <Page background={client.wantedMedia("Background", "FullscreenImage")}>
      <UpperLeftLogo src={client.wantedMedia("UpperLeftLogo", "PreserveSizeWithAlpha")} />
      <UpperRightLanguageButton />
      <Content>
        {renderComposition()}
        <MessageAndButtons>
          {renderMessage()}
          {renderButtons()}
        </MessageAndButtons>
      </Content>
    </Page>
  );
});

export default SharingPage;
