import _ from "lodash";
import React, { useCallback, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import * as PIXI from "pixi.js";
import { useImmer } from "use-immer";
import { Stage, Graphics, Container, Sprite } from "@inlet/react-pixi";

import { SnowflakeRoot, StageContainer } from "./styled";
import { resource, useMeasure, Point } from "ripple";
import { forwardRef } from "react";

const Snowflake = React.memo(
  forwardRef(({ lineType, onDraw, onChange, ...rest }, ref) => {
    const rootRef = useRef(null);

    const minLineLength = 10;

    const [measureRef, { width: viewWidth, height: viewHeight }] = useMeasure();

    const [lines, updateLines] = useImmer([]);

    const clampPositionBasedOnDistanceFromCenter = useCallback(
      (position) => {
        const maxDistance = viewWidth / 2;
        const positionFromCenter = new Point(position.x - viewWidth / 2, position.y - viewHeight / 2);

        const distanceFromCenter = Math.sqrt(Math.pow(positionFromCenter.x, 2) + Math.pow(positionFromCenter.y, 2));

        if (distanceFromCenter <= maxDistance) return position;

        const angle = Math.atan2(positionFromCenter.y, positionFromCenter.x);
        const x = Math.cos(angle) * maxDistance;
        const y = Math.sin(angle) * maxDistance;

        return new Point(x + viewWidth / 2, y + viewHeight / 2);
      },
      [viewWidth, viewHeight]
    );

    const isDraggingRef = useRef(false);
    const onPointerDown = useCallback(
      (e) => {
        onDraw();
        isDraggingRef.current = true;
        const rawPosition = clampPositionBasedOnDistanceFromCenter(e.data.getLocalPosition(e.currentTarget));
        const offsetPosition = { x: rawPosition.x - viewWidth / 2, y: rawPosition.y - viewHeight / 2 };
        updateLines((draft) => {
          draft.push({
            start: offsetPosition,
            end: offsetPosition,
            type: lineType,
          });
        });
      },
      [viewWidth, viewHeight, updateLines, lineType, clampPositionBasedOnDistanceFromCenter, onDraw]
    );

    const onPointerMove = useCallback(
      (e) => {
        if (!isDraggingRef.current) return;
        const position = clampPositionBasedOnDistanceFromCenter(e.data.getLocalPosition(e.currentTarget));
        // Update the end position for the last line in the array (the one we're currently drawing)
        updateLines((draft) => {
          const last = _.last(draft);

          const end = { x: position.x - viewWidth / 2, y: position.y - viewHeight / 2 };
          const dx = end.x - last.start.x;
          const dy = end.y - last.start.y;

          last.end = end;
          last.dx = dx;
          last.dy = dy;
          last.length = Math.sqrt(dx * dx + dy * dy);
        });
      },
      [viewWidth, viewHeight, updateLines, clampPositionBasedOnDistanceFromCenter]
    );

    const onPointerUp = useCallback(
      (e) => {
        isDraggingRef.current = false;
        // If the line we were creating is too short, remove it instantly to avoid
        // invisible "ghost lines" and small unsightly blobs.
        updateLines((draft) => {
          const last = _.last(draft);
          if (last?.length < minLineLength) draft.pop();
          onChange(draft?.length);
        });
      },
      [updateLines, onChange]
    );

    const onPointerCancel = useCallback(() => {
      isDraggingRef.current = false;
    }, []);

    const onPointerUpOutside = useCallback(() => {
      isDraggingRef.current = false;
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          updateLines(() => []);
          onChange(0);
        },
        undo: () => {
          updateLines((draft) => {
            if (draft.length > 0) draft.pop();
            onChange(draft.length);
          });
        },
        snapshot: () => {
          return new Promise((resolve) => {
            const root = rootRef.current;
            const canvas = root.querySelector("canvas");
            canvas.toBlob(resolve);
          });
        },
      }),
      [updateLines, onChange]
    );

    const renderDuplicatedSegment = (index, line) => {
      const angleOffset = Math.PI / 3;

      const image = resource(`images/Line_Type_0${line.type + 1}@2x.png`);
      const anchor = { x: 0.5, y: 0.92 };
      const rotation = Math.atan2(line.dy, line.dx) - Math.PI * 1.5;
      const scale = {
        x: viewWidth / 1024,
        y:
          (line.length / 240) /* The length of the image */ *
          1.2 /* Scale it up a bit to compensate for the margin in the image */,
      };

      // Avoid drawing the line until it's long enough
      if (line.length < minLineLength) return null;

      return (
        <Container key={`segment-${index}`}>
          <Container anchor={{ x: 0.5, y: 0.5 }}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 1}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 2}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 3}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 4}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 5}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>

          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 0} scale={{ x: -1, y: 1, z: 1 }}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 1} scale={{ x: -1, y: 1, z: 1 }}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 2} scale={{ x: -1, y: 1, z: 1 }}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 3} scale={{ x: -1, y: 1, z: 1 }}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 4} scale={{ x: -1, y: 1, z: 1 }}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
          <Container anchor={{ x: 0.5, y: 0.5 }} rotation={angleOffset * 5} scale={{ x: -1, y: 1, z: 1 }}>
            <Sprite image={image} anchor={anchor} position={line.start} rotation={rotation} scale={scale} />
          </Container>
        </Container>
      );
    };

    return (
      <SnowflakeRoot {...rest} ref={measureRef}>
        <StageContainer ref={rootRef} width={viewWidth} height={viewHeight}>
          <Stage
            options={{
              transparent: true,
              preserveDrawingBuffer: true, // Required to get an image when calling `canvas.toDataURL()`
              autoDensity: true,
            }}
            width={viewWidth}
            height={viewHeight}
          >
            <Graphics
              hitArea={new PIXI.Rectangle(0, 0, viewWidth, viewHeight)}
              interactive={true}
              pointerdown={onPointerDown}
              pointermove={onPointerMove}
              pointerup={onPointerUp}
              pointercancel={onPointerCancel}
              pointerupoutside={onPointerUpOutside}
            />
            <Container position={{ x: viewWidth / 2, y: viewWidth / 2 }}>
              {_.map(lines, (line, index) => renderDuplicatedSegment(index, line))}
            </Container>
          </Stage>
        </StageContainer>
      </SnowflakeRoot>
    );
  })
);

Snowflake.propTypes = {
  lineType: PropTypes.number,
  onDraw: PropTypes.func,
  onChange: PropTypes.func,
};

export default Snowflake;
