import { combineReducers } from "redux";

import modalViewer from "./ducks/modal-viewer-duck";

/** This is the state that the custom app manages */

export default () => {
  return combineReducers({
    modalViewer: modalViewer(),
  });
};
