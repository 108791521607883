import { Button, Image, LanguageButton, staggerStep, Text } from "ripple";
import styled from "styled-components";

export const simbiozBlue = "#00a5e3";

export const darkGrey = "#31363B";
export const lightBlue = "#2FA1C2";

export const screenMargin = "4vmin";

export const UpperLeftLogo = staggerStep({})(
  styled(Image)`
    position: absolute;
    top: ${screenMargin};
    left: ${screenMargin};
    max-width: 34vmin;
    margin-left: env(safe-area-inset-left);
    pointer-events: none;
  `
);

export const UpperRightLanguageButton = staggerStep({})(
  styled(LanguageButton)`
    position: absolute;
    top: ${screenMargin};
    right: ${screenMargin};
    font-size: 20px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    margin-right: env(safe-area-inset-right);
    z-index: 1;

    .hotspot {
      margin: -20px;
    }
  `
);

export const BottomButton = staggerStep({ transition: "slide-in-and-fade-from-bottom" })(
  styled(Button)`
    transition: all 250ms ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.disabled {
      opacity: 0.15;
    }

    &.clicked {
      transform: scale3d(0.8, 0.8, 0.8);
    }
  `
);

export const BottomButtonImage = styled(Image)`
  width: 16vmin;
  height: 16vmin;
  max-width: 70px;
  max-height: 70px;
`;

export const BottomButtonText = styled(Text)`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: white;
`;
