import { combineReducers } from "redux";

/**
 * This is the shared state that the IPC master manages. Slaves (even though
 * they still contain this reducer) do not maintain it as only the master tracks
 * shared actions and updates IPC shared state. This reducer should be treated
 * as write-only (never read directly). In all apps (including the master app),
 * access the shared state through `state.remote`.
 */

export default () => {
  return () => ({});
  // Return combineReducers({});
};
