import styled, { keyframes } from "styled-components";

import { Button, Image, Page as RipplePage, Text } from "ripple";
import Snow from "../../components/snow";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const scaleBackgroundSlowly = keyframes`
  0% { transform: scale3d(1, 1, 1) }
  100% { transform: scale3d(1.06, 1.06, 1.06) }
`;

export const scaleForegroundSlowly = keyframes`
  0% { transform: scale3d(1, 1, 1) }
  100% { transform: scale3d(1.16, 1.16, 1.16) }
`;

export const BackgroundImage = styled(Image).attrs((props) => ({ scaling: "fill" }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 3s ${scaleBackgroundSlowly} infinite ease-in-out alternate-reverse;
`;

export const ForegroundImage = styled(Image).attrs((props) => ({ scaling: "fill" }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 5s ${scaleForegroundSlowly} infinite ease-in-out alternate-reverse;
`;

export const SnowIsComing = styled(Text)`
  font-size: 28px;
  max-width: 80vmin;
  text-align: center;

  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: none;
`;

export const TapToContinue = styled(Text)`
  position: absolute;
  bottom: 4vmax;
  right: 4vmax;
  font-size: 18px;
  opacity: 0;

  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.show ? 0.5 : 0)};
  pointer-events: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  &:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f054";
    color: white;
    margin-left: 10px;
    margin-top: 3px;
  }
`;

export const FullscreenSnow = styled(Snow)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const FullscreenButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
