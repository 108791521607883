import { memo, useEffect, useState } from "react";
import { Config, resource, Strings, useData, useQueryString } from "ripple";
import { UpperLeftLogo, UpperRightLanguageButton } from "../../common";

import {
  BackgroundImage,
  ForegroundImage,
  FullscreenButton,
  FullscreenSnow,
  Page,
  SnowIsComing,
  TapToContinue,
} from "./styled";

const SnowingPage = memo(() => {
  const client = useData((data) => data.root);

  const { drawing } = useQueryString();

  const [showMessage, setShowMessage] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setShowMessage(false), Config.snowingMessageDuration);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Page>
      <BackgroundImage src={client.wantedMedia("BackgroundSnowing", "FullscreenImage")} />
      <FullscreenSnow src={resource("images/Snowing_flake_01.png")} spawnInterval={1700} />
      <FullscreenSnow src={resource("images/Snowing_flake_02.png")} spawnInterval={800} />
      <FullscreenSnow
        src={drawing}
        spawnInterval={1500}
        scaleMultiplier={0.4}
        opacityMultiplier={2}
        centerFirst={true}
      />
      <ForegroundImage src={client.wantedMedia("ForegroundSnowing", "FullscreenImageWithAlpha")} />
      <SnowIsComing show={showMessage}>{client.wantedText("SnowIsComing")}</SnowIsComing>
      <TapToContinue show={!showMessage}>{Strings.localized("TapToContinue")}</TapToContinue>
      <UpperLeftLogo src={client.wantedMedia("UpperLeftLogo", "PreserveSizeWithAlpha")} />
      <UpperRightLanguageButton />
      <FullscreenButton disabled={showMessage && !__DEV__} navigate={{ path: "/sharing", replaceQuery: { drawing } }} />
    </Page>
  );
});

export default SnowingPage;
