import { memo } from "react";
import { Route, useLocation } from "react-router";
import { Config, RouteTransitioner, useDebug, useData, useRemoteState } from "ripple";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

import AppUnderlay from "./components/app-underlay";
import AppOverlay from "./components/app-overlay";
import GracePopup from "./components/grace-popup";

import DrawingPage from "./pages/drawing-page";
import { darkGrey } from "./common";
import SnowingPage from "./pages/snowing-page";
import SharingPage from "./pages/sharing-page";
/* APP_PAGE_IMPORTS_INSERTION_POINT */

const StyledRippleRoot = createGlobalStyle`
  body {
    background-color: black;
  }

  #app .root {
    background-color: ${darkGrey};
  }
`;

const StyledAppRoot = styled.div`
  /* Customize app defaults here (fonts, etc.) */
  font-family: "SemplicitaPro";
  color: white;
`;

export const AppRootRouteTransitioner = styled(RouteTransitioner)`
  width: 100%;
  height: 100%;
`;

const AppRoot = memo(() => {
  const location = useLocation();
  const debug = useDebug();
  const data = useData();
  const remote = useRemoteState();

  const themeName = Config.theme;
  const themeObject = Config.themes[themeName];
  if (typeof themeObject === "undefined") throw new Error(`No theme named '${Config.theme}'`);

  const theme = {
    debug,
    name: themeName,
    ...themeObject,
  };

  // By default, transition only occurs when the first path component changes.
  // However, this might not make sense in all apps. Feel free to change this
  // to match your needs! For example, if you want a transition to occur every
  // time the URL changes (including the query string), use
  // `location.pathname + location.search` as a key.
  const transitionKey = location.pathname.split("/")[1];

  // Do not render pages as long as the data isn't available, as most pages depend on it heavily.
  const protect = (Component) => data && (!Config.ipc.enabled || !!remote) && Component;

  return (
    <ThemeProvider theme={theme}>
      <StyledRippleRoot />
      <StyledAppRoot style={{ width: "100%", height: "100%" }}>
        <AppUnderlay />
        <AppRootRouteTransitioner transitionKey={transitionKey} location={location}>
          <Route path="/drawing" component={protect(DrawingPage)} />
          <Route path="/snowing" component={protect(SnowingPage)} />
          <Route path="/sharing" component={protect(SharingPage)} />
          {/* APP_ROUTES_INSERTION_POINT */}
        </AppRootRouteTransitioner>
        <GracePopup />
        <AppOverlay />
      </StyledAppRoot>
    </ThemeProvider>
  );
});

export default AppRoot;
