import { createAction, createReducer } from "redux-act";

export const showModalViewer = createAction("showModalViewer");
export const hideModalViewer = createAction("hideModalViewer");

const reducerFactory = () => {
  const initialState = null;

  return createReducer(
    {
      [showModalViewer]: (state, payload) => payload,
      [hideModalViewer]: (state, payload) => null,
    },
    initialState
  );
};

export default reducerFactory;
